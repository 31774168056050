<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 10.237 12.708">
  <path id="Path_7714" data-name="Path 7714" d="M322.072,239.816l-5.785-5.854h2.551l5.786,5.854-5.786,5.854h-2.551Z" transform="translate(-315.089 -233.462)" :fill="color" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</svg>
</template>

<script>
export default {
  name: 'IconNext',
  props: {
    width: {
      type: Number,
      default: 10.237
    },
    height: {
      type: Number,
      default: 12.708
    },
    color: {
      type: String,
      default: '#0079c9'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
