<template>
  <v-dialog
    v-model="isVisible"
    max-width="350"
    content-class="bg-silver text-center"
    persistent
  >
    <div class="text-right">
      <button @click.stop="close" class="btn-close bg-primary">
        <div class="btn-close__icon" />
      </button>
    </div>
    <div class="px-6">
      <h2 class="mt-n7 mb-5 text-heading --prm">{{ $t("common.selectDate") }}</h2>
      <div class="min-width-300px">
        <Calendar />
        <v-row class="ma-0">
          <v-col class="px-0">
            <div class="text-normal --prm --w-bold">{{ $t("buttons.checkIn") }}</div>
            <div v-if="startDay" class="text-medium --c-light-gray my-1">
              {{ startDay | jaLongDate({hideDow: true}) }}
            </div>
            <v-container v-else class="pa-4"></v-container>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="2" class="text-title --c-light-gray align-self-end">
            <div>〜</div>
            <div class="nights-count">{{ dateDiffInDays(startDay, endDay) || '-' }}泊</div>
          </v-col>
          <v-col class="px-0">
            <div class="text-normal --prm --w-bold">{{ $t("common.checkOut") }}</div>
            <div v-if="endDay" class="text-medium --c-light-gray my-1">
              {{ endDay | jaLongDate({hideDow: true}) }}
            </div>
            <v-container v-else class="pa-4"></v-container>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-btn
          elevation="0"
          rounded
          class="text-medium --w-bold py-6 px-16 my-6"
          color="btn-grad--prm"
          @click="confirm"
          :disabled="!isReady"
        >
          {{ $t("buttons.decision") }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Calendar from '@/components/RoomCalendar'
import { dateDiffInDays, getJstToday } from '@/utils/get-date'

export default {
  name: 'Room',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    checkIn: {
      type: String
    },
    checkOut: {
      type: String
    },
    visibleDate: {
      type: String
    },
    noPersistent: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.makeShowDateVisible()
  },
  components: {
    Calendar
  },
  computed: {
    showDate () {
      return this.visibleDate || this.checkIn || this.checkOut || getJstToday()
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    startDay () {
      return this.$store.state.reservationCalendar.fromDate
    },
    endDay () {
      return this.$store.state.reservationCalendar.toDate
    },
    isReady () {
      return this.startDay && this.endDay
    }
  },
  watch: {
    visible (value) {
      if (this.noPersistent) {
        this.$store.commit('setReservationCalendarFromDate', null)
        this.$store.commit('setReservationCalendarToDate', null)
        if (value) {
          this.makeShowDateVisible()
          this.$store.commit('setReservationCalendarFromDate', this.checkIn)
          this.$store.commit('setReservationCalendarToDate', this.checkOut)
        }
      }
    }
  },
  methods: {
    dateDiffInDays,
    confirm () {
      this.$emit('confirm', {
        checkInDate: this.startDay,
        checkOutDate: this.endDay
      })
    },
    close () {
      this.$store.commit('setReservationCalendarFromDate', this.checkIn)
      this.$store.commit('setReservationCalendarToDate', this.checkOut)
      this.$emit('close')
    },
    makeShowDateVisible () {
      const date = new Date(this.showDate)
      this.$store.commit('setReservationDate', {
        year: date.getFullYear(),
        month: date.getMonth() + 1
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nights-count {
  font-size: var(--font-size__small);
  margin-bottom: -1.5em;
}
</style>
